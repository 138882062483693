.videos {
  padding-top: 180px;
  min-height: 100vh;
  padding-left: 50px;
  padding-right: 50px;
  height: 100vh;
  width: 100vw;

}

@media screen and (max-width: 700px) {

  .videos {
    padding-top: 100px;
    min-height: 100vh;
    padding-left: 50px;
    padding-right: 50px;
    height: 100vh;
 
    width: 100vw;
    
  }

  .container{
    
    height:100vh;
    overflow: scroll;
  }

  .items{
    overflow: scroll;
  }
}

