
/* beth-ellen-regular - latin */
@font-face {
  font-family: 'Beth Ellen';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/beth-ellen-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/beth-ellen-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/beth-ellen-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/beth-ellen-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/beth-ellen-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/beth-ellen-v17-latin-regular.svg#BethEllen') format('svg'); /* Legacy iOS */
}

/* josefin-sans-100 - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/josefin-sans-v25-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/josefin-sans-v25-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/josefin-sans-v25-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/josefin-sans-v25-latin-100.woff') format('woff'), /* Modern Browsers */
       url('./fonts/josefin-sans-v25-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/josefin-sans-v25-latin-100.svg#JosefinSans') format('svg'); /* Legacy iOS */
}
/* josefin-sans-300 - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/josefin-sans-v25-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/josefin-sans-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/josefin-sans-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/josefin-sans-v25-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/josefin-sans-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/josefin-sans-v25-latin-300.svg#JosefinSans') format('svg'); /* Legacy iOS */
}
/* josefin-sans-regular - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/josefin-sans-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/josefin-sans-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/josefin-sans-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/josefin-sans-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/josefin-sans-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/josefin-sans-v25-latin-regular.svg#JosefinSans') format('svg'); /* Legacy iOS */
}
/* josefin-sans-500 - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/josefin-sans-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/josefin-sans-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/josefin-sans-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/josefin-sans-v25-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/josefin-sans-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/josefin-sans-v25-latin-500.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

:root {
  --color: #D2BCBC;
  --normalcolor:#1f1d1d;
  --iconcolor:#726b6b;
  --main-font: "Josefin Sans";
  --heading-font: "Josefin Sans";
}
/**** Reset *****/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
  vertical-align: baseline;
}

/******* Reset Done ******/

*{
  font-family: var(--main-font);
  
  font-size: 1.2rem;
  font-weight: 100;
  box-sizing: border-box;
  line-height: 150%;
}

body{
  color:var(--normalcolor);
}

h1,h2,h3{
  font-family: var(--heading-font);
  
}


h1{
  font-size: 5vw;
  color:var(--color)
}

h2{
  font-size:2vw;
  font-family: "Beth Ellen";
  color:var(--color);
}

h3{
  font-size:2rem;
  color:var(--color);
}

.spotify{
 margin-top:20px;
  
 
}

.icon{
  color:var(--iconcolor);
  height:6vh;
  margin-right: 10px;;
  
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 700px) {
  h1 {
   
    color:white;
  }
  h2{
    font-size: 5vw;
    color: rgb(142, 118, 118);
  }
}


