.card{
    display: flex;
    margin-bottom: 20px;
    padding:15px;
    text-align: left;
    background-color: rgba(41, 30, 30, 0.327);
}

.card:hover{
    background-color: rgba(23, 18, 18, 0.327);
    cursor: pointer;
}

.date{
    border-right-style: solid;
    border-color: grey;
    border-width: 1px;
    padding-right: 15px;
    text-align: right;
    flex:1;
}

.location{
    padding-left:15px;
    font-weight: 400;
    text-align: left;
    flex:3;
}

.heading{
    font-weight: 400;
}