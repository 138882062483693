.card {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color);
  opacity: 0.9;
  padding: 8px;
  color: grey;
  background-color: rgba(255, 255, 255, 0.488);
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
}

.text {
  background-color: rgba(255, 255, 255, 0.502);
 
}

.media > * {
  margin-right: 8px;
}
.media > * :hover {
  margin-right: 20px;
  color: rgb(52, 182, 211);
  cursor: pointer;
}
