.full {
  width: 100vw;
  min-height: 100vh;
  padding-top: 100px;
  background-image: url("/public/images/songs.jpg");
  background-size: cover;
}

.content {
  top: 50;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  z-index: 50;
  overflow: scroll;
}

@media screen and (max-width: 700px) {
  .full {
    padding-top: 50px;
  }
}
