.full {
  min-height: 100vh;
  width: 100vw;
  background-image: url("/public/images/front.jpg");
  background-size: cover;
 
}

.content {
  padding-top: 30vh;
  padding-left: 10vw;
  font-size: 4rem;
}

@media screen and (max-width: 700px) {
  .full {
    min-height: 100vh;
    width: 100vw;
    background-image: url("/public/images/front_mobile.jpg");
    background-size: cover;
 
  }
}




