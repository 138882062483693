.full {
    min-height: 100vh;
    width: 100vw;
    background-image: url("/public/images/about.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
  }
  
  .content {
    height: 100wh;
    width: 45vw;
  
  }
  .content {
    padding: 40px;
  }
  .textbox {
    padding-left: 20px;
  }
  
  @media screen and (max-width: 700px) {
    .content {
      width:100vw;
    }
  }