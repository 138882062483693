.full {
 
  width: 100vw;
  min-height: 100vh;
  padding-top:100px;
  background-image: url("/public/images/live.jpg");
  background-size: cover;
}

.full {
  color: white;
}

.header {
  display: flex;
  justify-content: space-between;
}

.content {
  height: 100wh;
  

}
.content {
  padding: 40px;
  
}
.textbox {
  padding-left: 20px;
}

.card{
  margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .full {
    padding-top: 50px;
  }
}