

.firstname {
  font-weight: 100;
  font-family: var(--heading-font);
  color: var(--color);
}

.lastname {
  font-weight: 800;
  font-family: var(--heading-font);
  color: rgb(74, 39, 39);
}

.logo {
  position: relative;
  top: -17px;
}

.logo a {
  text-decoration: none;
  font-size: 50px;
  color: var(--color);
}

.logo:hover {
  cursor: pointer;
}

.header li {
  /*font-weight: 100;*/
}


.header {
  padding: 40px;
  display: block;
}

.header li {
  list-style-type: none;
}

.header li:hover {
  cursor: pointer;
  color: grey;
}

.logo {
  display: none;
}

.mobileMenu{
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding:15px;
  width: 100vw;
}

.mobileMenuIcon{
  display: flex;
}
.mobileMenuIcon p{
  position: relative;
  top:-4px;
  margin-left: 20px;
}

.mobileMenuIcon:hover{
  cursor: pointer;
}

.active{
  font-weight:400;
  
}

@media screen and (min-width: 700px) {

  .header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 50px;
    background-color: transparent;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 1000;
    -webkit-transition: background-color 1s ease-out;
    -moz-transition: background-color 1s ease-out;
    -o-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out;
  }

  .logo {
    display: block;
  }

  .list {
    display: inline;
  }

  .list li {
    display: inline-block;
    margin-right: 20px;
    position: relative;
  }

  .logo h1 {
    font-size: 40px;
    font-weight: 800;
  }
  
}
