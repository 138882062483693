.full {
  min-height: 100vh;
  width: 100vw;
  background-image: url("/public/images/contact.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
}

.content {
  height: 100wh;
  width: 45vw;
}
.content {
  padding: 40px;
}
.textbox {
  padding-left: 20px;
}

.partners{
  position: absolute;
  left:40px;
  bottom: 40px;
}
.partners a{
  text-decoration: none;
}
.impressum{
  position: absolute;
  right:40px;
  bottom: 40px;
}

.impressum a{
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  .content {
    width: 100vw;
  }

  .full {
    min-height: 100vh;
    width: 100vw;
    background-image: url("/public/images/contact_mobile.png");
    background-size: cover;
    display: flex;
    align-items: center;
  }
}
