
.songList{
    background-color: green;
    padding:50px;
}

.text{
    background-color: rgba(255, 255, 255, 0.584);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}